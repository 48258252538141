<template>
  <ApoCriaEntrada />
</template>

<script>
import ApoCriaEntrada from "@/components/documentacaoApoio/ApoioCriaEntrada.vue";

export default {
  components: {
    ApoCriaEntrada
  }
};
</script>
